#services{
    .service{
        border-radius: 1em;
        margin: 0.5em 1em;
        padding: 0 56px 0 0;
        align-items: center;
        
        .md-list-item-text{
            h3, h4, span {
                margin: 8px 0;
            }
        }

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        .indicador{
            margin: 0 4px;

            .icon-sync, .icon-sync-alert{
                font-size: 36px;
                width: 36px;
                height: 36px;
                line-height: 36px;
            }

            .icon-sync{
                color: green;
            }

            .icon-sync-alert{
                color: red;
            }
        }
    }
}