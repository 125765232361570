#roles{
    .rol{
        border-radius: 1em;
        margin: 0.5em 1em;
        align-items: center;

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        h6{
            margin: 12px 0;
        }

        span{
            font-size: 12px;
            text-align: end;
        }

        .badge {
            margin: 2px 4px;
            padding: 2px 4px;
            border-radius: 2px 8px;
            font-size: 12px;
        }
    }

    .group-roles {
        border: 2px solid rgb(57,73,171);
        padding: 0;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .group-header {
            font-size: 18px;
            padding: 4px 8px;
            background-color: rgb(57,73,171);
            color: rgba(255,255,255,0.87);
        }

        .group-content {

        }
    }
}

#editarRol{
    md-dialog-content {
        padding: 0;

        .menu{

            .menu-element {
                font-size: 14px;
                padding: 12px 8px;
                white-space: nowrap;
                cursor: pointer;

                &.selected {
                    background-color: orangered;
                }

                md-icon {
                    margin-right: 0;
                }
            }
        }

        .content {
            overflow: auto;
            padding: 18px;

            md-checkbox{
                margin-bottom: 4px;

                span{
                    word-break: break-word;
                }

                .md-container:after{
                    top: -1px;
                    bottom: -1px;
                    left: -1px;
                    right: -1px;
                }
            }

            .text-boxed {
                white-space: normal;
            }

            .raw-data-textarea {
                border: 1px solid rgb(169, 169, 169);
                padding: 2px;
                font: 13px monospace;
            }
        }
    }
}