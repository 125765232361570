#grupos {

    .grupo{
        border: 3px solid rgb(57,73,171);
        padding: 0;
        margin: 8px;
        border-radius: 8px;
        padding-bottom: 0.5em;

        .title{
            font-size: 18px;
            padding: 8px;
            margin-bottom: 0.5em;
        }

        .equipo {
            margin: 0 8px 8px;

            p:first-child {
                font-size: 16px;
                font-weight: bold;
                margin-right: 8px;
            }

            .icon-star {
                margin: 0;
                margin-right: 8px;
                color: #bb9e00;
            }

            .usuario{
                border-radius: 4em;
                margin: 4px 8px 4px 0;
                align-items: center;
                padding: 4px;

                .md-avatar{
                    border-radius: 50%;
                    margin-right: 4px;
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }

                span {
                    margin: 0 4px;
                    font-size: 14px;
                }

                &.user-disabled {
                    background: #9E9E9E !important;
                    opacity: 0.5;
                }
            }
        }
    }
}

#select-admins {
    .grupo{
        border: 3px solid rgb(57,73,171);
        padding: 0;
        margin: 8px;
        border-radius: 8px;
        padding-bottom: 0.5em;

        .title{
            font-size: 18px;
            padding: 8px;
            margin-bottom: 0.5em;
        }

        .usuario{
            border-radius: 4em;
            margin: 4px;
            align-items: center;
            padding: 4px;
            background: lightgray;

            md-checkbox {
                margin-left: 8px;
            }

            .md-avatar{
                border-radius: 50%;
                margin-right: 4px;
                width: 30px;
                height: 30px;
                object-fit: contain;
            }

            span {
                margin: 0 4px;
                font-size: 14px;
            }

            &.user-disabled {
                background: #9E9E9E !important;
                opacity: 0.5;
            }
        }
    }
}

#select-equipos {
    .equipo{
        border-radius: 4em;
        margin: 4px;
        align-items: center;
        padding: 8px;
        background: lightgray;

        md-checkbox {
            margin-left: 4px;
        }

        span {
            margin: 0 4px;
            font-size: 14px;
        }
    }
}

#select-servicios {
    .service {
        border-radius: 4em;
        margin: 4px;
        align-items: center;
        padding: 8px;
        background: lightgray;

        md-checkbox {
            margin-left: 4px;
        }

        span {
            margin: 0 4px;
            font-size: 14px;
        }
    }
}