#ajustes{
    textarea {
        border: 1px solid rgb(169,169,169);
    }

    input, textarea {
        &:not(:first-child) {
            margin-left: 8px;
        }
    }
    
    .layout-align-start-start {
        margin-bottom: 2px;
        
        input {
            margin-top: 9px;
            margin-bottom: 9px;
        }
        
        textarea {
            min-width: 25vw;
        }
    }
}